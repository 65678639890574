import * as React from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import CustomButton from '../CustomButton';

function isBrowser() {
  return typeof window !== 'undefined';
}

function getValue(key, defaultValue) {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue;
}

function setValue(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyState(defaultValue, key) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });

  React.useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}

const CookieConsent = () => {
  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    'consentCookieHidden',
  );

  const EnableAnalytics = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    setBannerHidden(true);
  };

  const RefuseAnalytics = () => {
    setBannerHidden(true);
  };

  const [timerPassed, setTimerPassed] = React.useState(false);
  React.useEffect(() => {
    if (bannerHidden === false) {
      const timer = setTimeout(() => setTimerPassed(true), 4000);
      return () => { clearTimeout(timer); };
    }
    return null;
  }, [bannerHidden]);

  return (
    <>
      {(bannerHidden === false && timerPassed) && (
        <div className={`${(bannerHidden) ? "hidden" : "relative"}`}>
          <div className="fixed bottom-0 z-50 w-full py-4 bg-white border-t border-gray-400 pl-10 pr-10">
            <div className="flex flex-col items-center md:flex-row justify-between">
              <div className="h-full py-2 text-xxs md:text-xxm text-black md:self-center md:w-4/5">
                <p>Zawema.com et ses partenaires utilise des cookies afin de vous proposer une expérience de navigation personnalisée, pour assurer des fonctionnalités relatives aux réseaux sociaux et à l'analyse du trafic. En cliquant sur "Accepter", vous acceptez l’utilisation des cookies.</p>
              </div>
              <div className="flex flex-row space-x-3 pb-4 md:flex-col md:space-x-0 md:space-y-3 md:pb-0">
                <CustomButton buttonStyle="bg-orange-darkest text-white" children={"Accepter"} action={() => EnableAnalytics()} />
                <CustomButton buttonStyle="bg-orange-dark text-white" children={"Refuser"} action={() => RefuseAnalytics()} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;