import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";

const SecondLevelItems = ({ parentSlug, slug, categoryName, dropdown }) => {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <li
      key="FirstLevelCateg"
      className="flex flex-col content-center justify-between py-2 text-grey-dark text-s outline-none w-full"
    >
      <a href={`/fr/produits/${parentSlug}/${slug}`}
        className={`text-grey-dark text-s w-full font-${isShowing ? "semibold" : "normal"}`}
      >
        {categoryName}
      </a>
      {(isShowing) ?
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="flex h-5 w-5 md:hidden absolute right-12"
          viewBox="0 0 20 20"
          fill="currentColor"
          onClick={() => setIsShowing(false)}
        >
          <path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>

        :
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="flex h-5 w-5 md:hidden absolute right-12"
          viewBox="0 0 20 20"
          fill="currentColor"
          onClick={() => setIsShowing(true)}
        >
          <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
      }
      <Transition
        as={Fragment}
        show={isShowing}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-500"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        {dropdown}
      </Transition>
    </li>
  );
};

export default SecondLevelItems;

SecondLevelItems.propTypes = {
  slug: PropTypes.string,
  parentSlug: PropTypes.string,
  categoryName: PropTypes.string

};