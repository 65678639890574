const getStyles = (theme) => ({
  searchInput: {
    border: " solid 0.25px #AFAFAF",
    color: "#AFAFAF",
    fontSize: "12px",
  },
  invisible: {
    display: "none",
  },
  okButton: {
    right: "28%",
    top: 15,
  }
});

export default getStyles;
