
const getStyles = (theme) => ({
  firstRow: {
    paddingRight: "5%",
    paddingLeft: "3%",
    width: "100%"
  },
  thirdRow: {
    marginTop: 5,
    paddingLeft: 5
  },
  searchbar: {
    height: "10%",
    paddingTop: "5%",
    paddingBottom: "5%",
    borderBottom: "solid 1px #AFAFAF",
  }
});

export default getStyles;