const getStyles = (theme) =>({
  root: {
    background: '#fff',
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    borderRadius: 183,
    width: '90%',
  },
  popupMessage: {
    color: 'black',
    fontWeight: 'bold',
    textTransform: 'initial',
    textAlign: 'center'
  },
  cancelIcon: {
    fontSize: 70
  },
  checkIcon: {
    color: 'green',
    fontSize: 70
  }
});

export default getStyles;