import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";
import { resetPopupStore } from "../../state/reducer";

const CustomAlert = ({ withError, message }) => {
  const classes = useStyles(getStyles());
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    dispatch(resetPopupStore());
  };
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={handleClose}
      >
        <div className="container justify-center">
          <div className="m-4">
            <h2 style={classes.popupMessage}>{message}</h2>
          </div>

          {withError ? (
            <div className="container justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleClose}
                style={classes.cancelIcon}
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          ) : (
            <div className="container justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleClose}
                style={classes.checkIcon}
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CustomAlert;

CustomAlert.propTypes = {
  withError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

CustomAlert.defaultProps = {
  withError: false,
};
