import React from "react";
import PropTypes from "prop-types";

const ThirdLevelItems = ({ parentSlug, parentParentSlug, slug, categoryName }) => {

  return (
      <li
        key="FirstLevelCateg"
        className="text-grey-dark text-s outline-none py-2 pr-14"
      >
        <a href={`/fr/produits/${parentParentSlug}/${parentSlug}/${slug}`}
          className="text-grey-dark text-s md:text-base hover:font-semibold w-full"
        >
          {categoryName}
        </a>
      </li>
  );
};

export default ThirdLevelItems;

ThirdLevelItems.propTypes = {
  slug: PropTypes.string,
  parentParentSlug: PropTypes.string,
  parentSlug: PropTypes.string,
  categoryName: PropTypes.string

};