import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

// use it when you cannot use heroicons
const Icon = ({ icon, size, link, style }) => {
  const classes = useStyles(getStyles());
  return link ? (
    <a href={link} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={icon} size={size} style={[classes.iconColor, style]} />

    </a>
  ) : (
      <FontAwesomeIcon icon={icon} size={size} style={classes.iconColor}/>
  );
};

export default Icon;

Icon.propTypes = {
  icon: PropTypes.object.isRequired,
  size: PropTypes.string,
  link: PropTypes.string,

};

Icon.defaultProps = {
  size: "3x",
};
