import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchLaunched, setSearchSkip } from "../../state/reducer";
import getStyles from "./styles";
import { useStyles } from "react-styles-hook";
import { SEARCH_SKIP } from "../../utils/constants";
import { withTranslation } from "react-i18next";

const Searchbar = ({ currentPath, t, locale, isOpen, setIsOpen }) => {
  const classes = useStyles(getStyles());
  const dispatch = useDispatch();
  const searchLocale = locale;

  const { searchValue } = useSelector((state) => ({
    searchValue: state.getIn(["root", "searchValue"]),
  }));

  const [value, setValue] = useState(searchValue);

  const handleSubmit = (e) => {
    e.preventDefault(); // Important to submit form when user press "Enter"
    if (value) {
      dispatch(getSearchLaunched({ value, searchLocale, currentPath }));
      // close filter view on mobile
      if (isOpen) {
        setIsOpen(!isOpen);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full flex">
        <div className="w-full flex flex-row md:relative">
          {/* search icon for mobile view */}
          <div className="md:hidden mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mb-1 z-50 text-orange-dark text-semibold inline-flex"
              viewBox="0 0 20 20"
              fill="currentColor"
              type="submit"
              onClick={handleSubmit}
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            placeholder={t("Search")}
            id="search"
            name="search"
            onChange={(event) => setValue(event.target.value)}
            defaultValue={searchValue}
            style={classes.searchInput}
            className="h-8 md:h-10 pr-8 pl-5 rounded z-0 ring-shadow-none ring-transparent 2xl:w-96 md:w-80 w-5/6 ml-2 md:ml-0"
          />
          {/* ok button for mobile view  */}
          <button type="button" style={classes.okButton} className="h-8 bg-orange-dark md:hidden w-9 text-grey-dark text-xs outline-none" onClick={handleSubmit}>
            OK
          </button>
          <div className="absolute top-2 right-3 hidden md:block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mb-1 z-20 text-orange-dark inline-flex"
              viewBox="0 0 20 20"
              fill="currentColor"
              type="submit"
              onClick={handleSubmit}
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <button type="submit" label="" style={classes.invisible} onSubmit={handleSubmit} />
    </form>
  );
};

export default withTranslation()(Searchbar);
