/**
 * @param {string} url - l'url à checker
 * @param {*} protocol - le protocole à ajouter
 * @returns l'url précédée du protocole choisi
 */
export const addProtocol = (url, protocol) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = `${protocol}://${url}`;
  }
  return url;
};

/**
 * @param {number} date - Timestamp with milliseconds
 * @returns the date formatted as "2021-05-05"
 */
export const formatTimestampToDateWithDashes = (date) => {
  return new Date(date).toISOString().split("T")[0];
};

/**
 * @param {string} date - date as "2021-05-13"
 * @returns Date as "13-05-201"
 */
export const formatDateToFrenchFormat = (date) => {
  const splittedEnglishDate = date?.split("-");
  if (splittedEnglishDate) {
    return `${splittedEnglishDate[2]}-${splittedEnglishDate[1]}-${splittedEnglishDate[0]}`;
  }
};

/**
 *
 * @param {number} date - Timestamp
 * @param {number} numberOfDays
 * @returns - date + numberOfDays as timestamp
 */
export const addDaysToTimestamp = (date, numberOfDays) => {
  return date + 24 * 60 * 60 * 1000 * numberOfDays;
};

/**
 * @param {string} string - string as "text to Transform"
 * @returns string as "Text to transform"
 */
export const capitalizeFirst = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return null;
};

export const formatCityName = (string) => {
  let stringToReturn = "";

  // delete accent
  const b = "áàâäãåçéèêëíïîìñóòôöõúùûüýÁÀÂÄÃÅÇÉÈÊËÍÏÎÌÑÓÒÔÖÕÚÙÛÜÝ";
  const c = "aaaaaaceeeeiiiinooooouuuuyAAAAAACEEEEIIIINOOOOOUUUUY";
  for (let i = 0, j = string.length; i < j; i += 1) {
    const e = string.substr(i, 1);
    stringToReturn += (b.indexOf(e) !== -1) ? c.substr(b.indexOf(e), 1) : e;
  }

  return stringToReturn.toLowerCase();
};

/**
 * @param {array} array - array [[createdDate: yyyy-mm-dd, otherKey: otherPropertyX], [createdDate: yyyy-mm-dd, otherKey: otherPropertyY]]
 * @returns array sort by date Desc
 */
export const dateComparison = (a, b) => {
  const date1 = new Date(a.createdDate);
  const date2 = new Date(b.createdDate);
  return date2 - date1;
};