import React from "react";
import { Disclosure } from "@headlessui/react";
import PropTypes from "prop-types";
import Searchbar from "../Searchbar";
import ShoppingCart from "../ShoppingCart";
import { Link } from "gatsby";
import Logo from "../../../static/logoMini.png";
import LogoTitle from "../../../static/ZawemaMini.png";
import BurgerMenu from '../BurgerMenu';

const Header = ({
  currentPath,
  firstLevelCategories,
  secondLevelCategories,
  thirdLevelCategories,
  lang,
  isAuthenticated }) => {

  return (
    <Disclosure as="header">
      {({ open }) => (
        <div className="flex justify-start">
          <BurgerMenu
            currentPath={currentPath}
            firstLevelCategories={firstLevelCategories}
            secondLevelCategories={secondLevelCategories}
            thirdLevelCategories={thirdLevelCategories}
            lang={lang}
            isAuthenticated={isAuthenticated}
          />
          <div className="w-full pt-6 lg:mx-28 pr-10 md:mt-10 shadow-slate-400">
            <div className="relative h-16 flex justify-between">
              <div className="relative z-10 px-2 flex lg:px-0">
                <h1
                  className="flex-shrink-0 flex items-center md:mb-10 mb-12 md:pr-4 pl-10 md:pl-0"
                  title="Logo Marketplace Zawema"
                  aria="Marketplace Zawema Image"
                >
                  <Link to="/">
                    <img
                      className="block h-10 md:h-16 w-auto"
                      src={Logo}
                      alt="Logo zawema"
                    />

                  </Link>
                </h1>
                <div className="flex-shrink-0 flex items-center md:mb-10 mb-11 md:mt-6">
                  <Link to="/">
                    <img
                      className="block md:h-7 md:w-36 w-28 h-5 md:ml-5 ml-3"
                      src={LogoTitle}
                      alt="Zawema brand"
                    />
                  </Link>
                </div>
              </div>
              <div className="hidden md:block">
                <Searchbar currentPath={currentPath} locale={lang} />
              </div>
              {/* Account icon section */}
              <div className="grid grid-flow-col auto-cols-max">
                <div className="hidden md:relative md:z-10 md:ml-4 md:flex pr-4">
                  {isAuthenticated ? (
                    <Link to={`/${lang}/account`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 text-grey-light hover:text-grey-dark"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        rel="noopener noreferrer"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  ) : (
                    <Link to={`/${lang}/signup`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 text-grey-light hover:text-grey-dark"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        rel="noopener noreferrer"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  )}
                </div>
                <ShoppingCart lang={lang} />
              </div>
            </div>
          </div>
          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="mt-3 px-2 space-y-1">
                <Link to={`/${lang}/signup`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-grey-light"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </Disclosure.Panel>
        </div>
      )
      }
    </Disclosure >
  );
};

export default Header;

Header.propTypes = {
  firstLevelCategories: PropTypes.array,
  secondLevelCategories: PropTypes.array,
  thirdLevelCategories: PropTypes.array,
};