import React from 'react';
import { Link } from "gatsby";
import PropTypes from 'prop-types';
import { addProtocol } from '../../utils/format';
import clsx from 'clsx';

// styled component css with tailwind class

const CustomButton = ({ link, text, isOutsideLink, type, children, disabled, action, buttonStyle }) => {

  const renderContent = () => {
    if (children) {
      return <p>{children}</p>
    } else if (link) {
      return isOutsideLink
        ? <a href={addProtocol(link, "https")} target="_blank" rel="noreferrer" className="font-bold">{text}</a>
        : <Link to={link} className="font-bold">{text}{isOutsideLink}</Link>
    }
  }

  return (
    <>
      {!link && <button
        className={clsx(buttonStyle ? buttonStyle : "bg-orange-dark hover:bg-orange-chocolate focus:outline-none text-white flex", "flex py-2 px-6 md:px-10 text-xxm md:text-sm text-white")}
        type={type}
        disabled={disabled}
        onClick={action && action}
      >
        {renderContent()}
      </button>
      }
      {link && isOutsideLink &&
        <a href={addProtocol(link, "https")} target="_blank" rel="noreferrer" className="font-bold">
          <button
            className={clsx(buttonStyle ? buttonStyle : "bg-orange-dark hover:bg-orange-chocolate focus:outline-none text-white flex", "flex py-2 px-6 md:px-10 text-xxm md:text-sm text-white")}
            type={type}
            disabled={disabled}
            onClick={action && action}
          >
            {renderContent()}
          </button>
        </a>
      }
      {link && !isOutsideLink &&
        <Link to={link} className="font-bold">
          <button
            className={clsx(buttonStyle ? buttonStyle : "bg-orange-dark hover:bg-orange-chocolate focus:outline-none text-white flex", "flex py-2 px-6 md:px-10 text-xxm md:text-sm text-white")}
            type={type}
            disabled={disabled}
            onClick={action && action}
          >
            {renderContent()}
          </button>
        </Link>
      }
    </>
  )
}

export default CustomButton

CustomButton.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  buttonStyle: PropTypes.string,
  isMaxWidth: PropTypes.bool,
  isOutsideLink: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

CustomButton.defaultProps = {
  isMaxWidth: false,
  isOutsideLink: false,
  disabled: false
};
