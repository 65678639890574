import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Transition } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import { Link } from "gatsby";
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

import FirstLevelItems from './FirstLevelItems';
import SecondLevelItems from './SecondLevelItems';
import ThirdLevelItems from './ThirdLevelItems';
import Searchbar from '../Searchbar';

const BurgerMenu = ({
  currentPath,
  firstLevelCategories,
  secondLevelCategories,
  thirdLevelCategories,
  lang,
  isAuthenticated
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles(getStyles());


  let body;
  if (typeof window !== "undefined") { // during build window is unavailable
    body = document.querySelector("body");
  }
  useEffect(() => {
    if (isOpen && typeof window !== "undefined") {
      body.style.overflow = "hidden"; // block scroll on body when menu is open
    };
    if (!isOpen && typeof window !== "undefined") {
      body.style.overflow = "auto"; // block scroll on body when menu is open
    }
  }, [isOpen]);

  return (
    <>
      <div className="relative pl-4 top-6 z-20">
        <MenuIcon
          className="block h-6 w-6 text-grey-light md:hidden"
          aria-hidden="true"
          onClick={() => setIsOpen(true)}
        />
      </div>
      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 transform translate-x-0"
        enterTo="opacity-100 transform translate-x-1"
        leave="transition ease-in duration-500"
        leaveFrom="opacity-100 transform translate-x-0"
        leaveTo="opacity-0 transform translate-x-1"
      >
        <div className="w-screen h-screen m-0 p-0 bg-white z-50 fixed overflow-y-auto top-0 left-0">
          <nav className="w-screen bg-white z-50 m-0 p-0">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="bg-orange-dark text-white w-8 h-8"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 28">
                  <g id="Groupe_184" data-name="Groupe 184" transform="translate(-378 -8)">
                    <rect id="Rectangle_138" data-name="Rectangle 138" transform="translate(378 9)" fill="#f5b091" />
                    <text id="X" transform="translate(378 8)" fill="#fff" font-size="18" font-family="Poppins-Regular, Poppins"><tspan x="6.29" y="18">X</tspan></text>
                  </g>
                </svg>
              </button>
            </div>
            <div className="w-screen bg-white z-50">
              <ul className="absolute bg-white z-50" style={classes.firstRow}>
                <li style={classes.searchbar}>
                  <Searchbar currentPath={currentPath} locale={lang} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                {firstLevelCategories?.map(({ node: firstLevelCategory }) => (
                  <FirstLevelItems
                    slug={firstLevelCategory.slug}
                    categoryName={firstLevelCategory.categoryName}
                    dropdown={
                      <ul className="bg-white z-50 pt-2">
                        {secondLevelCategories?.map(({ node: secondLevelCategory }) => secondLevelCategory.parentCategory.slug === firstLevelCategory.slug && (
                          <SecondLevelItems
                            slug={secondLevelCategory.slug}
                            parentSlug={secondLevelCategory.parentCategory.slug}
                            categoryName={secondLevelCategory.categoryEncartTitle ? secondLevelCategory.categoryEncartTitle : secondLevelCategory.categoryName}
                            dropdown={
                              <ul classname="bg-white z-50 pl-2" style={classes.thirdRow}>
                                {thirdLevelCategories?.map(({ node: thirdLevelCategory }) => thirdLevelCategory.parentCategory.slug === secondLevelCategory.slug && (
                                  <ThirdLevelItems
                                    slug={thirdLevelCategory.slug}
                                    parentSlug={secondLevelCategory.slug}
                                    parentParentSlug={secondLevelCategory.parentCategory.slug}
                                    categoryName={thirdLevelCategory.categoryEncartTitle ? thirdLevelCategory.categoryEncartTitle : thirdLevelCategory.categoryName}
                                  />
                                ))}
                              </ul>
                            }
                          />
                        ))}
                      </ul>
                    }
                  />
                ))}
                {/* Menu section linking to CMS pages */}
                {/* <li className="px-6 py-4 z-50 border-grey-light">
                  <Link to={`/${lang}/coaching`}  className="text-grey-light hover:text-grey-dark text-s font-medium hover:font-semibold" rel="noopener noreferrer">
                    Coaching
                  </Link>
                </li> */}
                <li className="px-6 py-4 z-50 border-grey-light border-b">
                  <Link to={`/${lang}/advice`} className="text-grey-light hover:text-grey-dark text-s hover:font-semibold" rel="noopener noreferrer">
                    Blog
                  </Link>
                </li>
                <li className="px-6 py-4 z-50 border-grey-light border-b">
                  <Link to={`/${lang}/salons`} className="text-grey-light hover:text-grey-dark text-s hover:font-semibold" rel="noopener noreferrer">
                    Salons
                  </Link>
                </li>
                {/* for mobile view */}
                <li className="md:hidden z-50 py-6 px-4">
                  <Link to={isAuthenticated ? `/${lang}/account` : `/${lang}/signup`} className="flex" onClick={() => setIsOpen(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 text-grey-light hover:text-grey-dark"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-s text-grey pt-3 pl-2">
                      {isAuthenticated ? "Mon compte" : "S'identifier"}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </Transition>
    </>
  );
};

export default BurgerMenu;

BurgerMenu.propTypes = {
  firstLevelCategories: PropTypes.array,
  secondLevelCategories: PropTypes.array,
  thirdLevelCategories: PropTypes.array,
};