import React, { useEffect, useState } from "react";
import getStyles from "./styles";
import { useDispatch } from 'react-redux';
import { Link } from "gatsby";
import { useStyles } from "react-styles-hook";
import { menuCategories } from "../../utils/menuCategories";
import { addProtocol } from "../../utils/format";
import Icon from "../Icon";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import useWindowSize from "../../utils/utils";
import { newsletterSubscriptionLaunched } from '../../state/reducer';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import CookieConsent from '../CookieConsent';

export default function Footer({ lang }) {

  const classes = useStyles(getStyles());
  const size = useWindowSize();
  const dispatch = useDispatch();

  // NEWSLETTER SUBSCRIPTION
  const [subscribeSuccess, setSubscrireSuccess] = useState(false);
  const [newsletterValue, setNewsLetterValue] = useState(
    { emailNewsletter: '' }
  );

  const initialValues = {
    emailNewsletter: newsletterValue.emailNewsletter,
  };

  const ValidationSchema = Yup.object({
    emailNewsletter: Yup.string()
      .required("Adresse email obligatoire")
      .email("Mauvais format de mail"),
  });

  const subscribe = () => {
    dispatch(newsletterSubscriptionLaunched(newsletterValue));
    setSubscrireSuccess(true);
  };

  return (
    <div className="max-w-full mb-10">
      <div className="flex space-x-4 w-full bg-orange justify-center h-auto">
        <div className="flex flex-wrap w-5/6 items-center justify-center">
          <div className="w-1/2 md:w-1/4 flex items-center flex-col p-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mb-4 text-grey" viewBox="0 0 37.417 38.763">
              <g id="Groupe_167" data-name="Groupe 167" transform="translate(-90.294 -91.964)">
                <path id="Tracé_44" data-name="Tracé 44" d="M17.958,0c9.918,0,17.958,8.342,17.958,18.631s-8.04,18.631-17.958,18.631S0,28.921,0,18.631,8.04,0,17.958,0Z" transform="translate(91.044 92.714)" fill="none" stroke="#515151" stroke-width="1.5" />
                <circle id="Ellipse_6" data-name="Ellipse 6" cx="1.906" cy="1.906" r="1.906" transform="translate(101.008 105.376)" fill="#515151" />
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="1.906" cy="1.906" r="1.906" transform="translate(113.516 105.376)" fill="#515151" />
                <path id="Tracé_43" data-name="Tracé 43" d="M113.568,147.527s8.634,9.964,16.523,0" transform="translate(-12.932 -31.471)" fill="none" stroke="#515151" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
              </g>
            </svg>
            <h3 className="text-grey uppercase text-center">
              Satisfait ou remboursé
            </h3>
          </div>
          <div className="w-1/2 md:w-1/4  flex items-center flex-col p-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mb-4 text-grey" viewBox="0 0 31.45 41.59">
              <g id="Groupe_77" data-name="Groupe 77" transform="translate(-0.717 -0.732)">
                <path id="Tracé_39" data-name="Tracé 39" d="M8.059,29.479A21.168,21.168,0,0,0,8.107,43.45l4.054-.375a1.715,1.715,0,0,1,1.742.941c.49,1.261,2.455,6.135,2.455,6.135a1.451,1.451,0,0,1-.24,1.467c-.476.476-5.388,3.388-5.388,3.388a1.222,1.222,0,0,1-1.627-.24c-.855-.878-16.467-17.372-.316-36.373a1.656,1.656,0,0,1,2.193-.354c1.56.986,4.912,3.095,4.912,3.095a1.354,1.354,0,0,1,.4,1.647c-.534,1.228-2.442,6.331-2.442,6.331a1.216,1.216,0,0,1-1.022.738C12.06,29.906,8.059,29.479,8.059,29.479Z" transform="translate(0 -13.714)" fill="none" stroke="#515151" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                <path id="Tracé_40" data-name="Tracé 40" d="M110.942,47.689a2.6,2.6,0,0,1,.179,1.973c-.384.922-.506.973-.023,1.537s1.124,1.178,1.739.435,1.614-2.152.2-5.278a.89.89,0,0,0-1.46-.256C110.635,46.869,110.685,46.94,110.942,47.689Z" transform="translate(-92.194 -37.385)" fill="none" stroke="#515151" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                <path id="Tracé_41" data-name="Tracé 41" d="M134.258,25.189s-.564-.564-.154-1.076,1.2-1.691,1.973-.692,4.535,6.558,0,12.451a.934.934,0,0,1-1.307,0c-.769-.589-1.076-.794-.41-1.87A7.642,7.642,0,0,0,134.258,25.189Z" transform="translate(-111.745 -18.165)" fill="none" stroke="#515151" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                <path id="Tracé_42" data-name="Tracé 42" d="M157.828,3.514s-.5-.656-.282-1.05c.323-.595,1.314-1.431,2.075-.64.867.9,7.267,8.8-.179,19.394,0,0-.309.478-.973.094s-1.329-.682-.769-1.783S163.869,12.037,157.828,3.514Z" transform="translate(-131.599 0)" fill="none" stroke="#515151" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
              </g>
            </svg>
            <h3 className="text-grey uppercase text-center">
              Service client de 9h à 18h
            </h3>
          </div>
          <div className="w-1/2 md:w-1/4 flex items-center flex-col p-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mb-4 text-grey" viewBox="0 0 39.352 31">
              <g id="Groupe_81" data-name="Groupe 81" transform="translate(-162.256 -93.89)">
                <g id="Groupe_80" data-name="Groupe 80" transform="translate(163.006 94.64)">
                  <path id="Tracé_48" data-name="Tracé 48" d="M163.006,97.619s.1-2.979,2.93-2.979h31.746s3.175,0,3.175,2.784v3.516H163.006Z" transform="translate(-163.006 -94.64)" fill="none" stroke="#515151" stroke-miterlimit="10" stroke-width="1.5" />
                  <path id="Tracé_49" data-name="Tracé 49" d="M163.006,145.57h37.852v11.77s.049,2.882-2.442,2.882-33.655-.268-33.655-.268a2.2,2.2,0,0,1-1.754-2.125Z" transform="translate(-163.006 -130.723)" fill="none" stroke="#515151" stroke-miterlimit="10" stroke-width="1.5" />
                  <rect id="Rectangle_133" data-name="Rectangle 133" width="5.95" height="4.194" rx="2.097" transform="translate(4.334 21.136)" fill="none" stroke="#515151" stroke-miterlimit="10" stroke-width="1.5" />
                  <rect id="Rectangle_134" data-name="Rectangle 134" width="10.491" height="4.194" rx="2.097" transform="translate(12.698 21.136)" fill="none" stroke="#515151" stroke-miterlimit="10" stroke-width="1.5" />
                </g>
                <rect id="Rectangle_135" data-name="Rectangle 135" width="37.851" height="8.547" transform="translate(163.006 100.94)" fill="none" stroke="#515151" stroke-miterlimit="10" stroke-width="1.5" />
              </g>
            </svg>
            <h3 className="text-grey uppercase text-center">
              Paiement sécurisé
            </h3>
          </div>
          <div className="w-1/2 md:w-1/4 flex items-center flex-col p-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mb-4 text-grey" viewBox="0 0 38.821 31.269">
              <g id="Groupe_79" data-name="Groupe 79" transform="translate(-129.132 -109.235)">
                <path id="Tracé_45" data-name="Tracé 45" d="M129.883,112.821a2.822,2.822,0,0,1,2.975-2.836h18.223s2.945,0,2.975,2.464c.026,2.138,0,3.3,0,3.3h2.6a3.217,3.217,0,0,1,2.046.883c1.526,1.219,6.136,6.043,6.136,6.043a2.721,2.721,0,0,1,.465,1.534v6.322h.986s.911.072.911.893V133.3s-.072.84-.625.84h-3.2s-.092,5.613-5.164,5.613-5.848-3.926-5.848-5.6l-7.525.123s-.368,5.48-5.4,5.48a5.673,5.673,0,0,1-5.807-5.562H131.92a2.052,2.052,0,0,1-2.037-2.331C129.912,130.43,129.883,112.821,129.883,112.821Z" fill="none" stroke="#515151" stroke-miterlimit="10" stroke-width="1.5" />
                <path id="Tracé_47" data-name="Tracé 47" d="M2.844,0A2.844,2.844,0,1,1,0,2.844,2.844,2.844,0,0,1,2.844,0Z" transform="translate(136.474 131.39)" fill="none" stroke="#515151" stroke-width="1.5" />
                <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.844" cy="2.844" r="2.844" transform="translate(155.032 131.39)" fill="none" stroke="#515151" stroke-miterlimit="10" stroke-width="1.5" />
                <path id="Tracé_46" data-name="Tracé 46" d="M209.838,137.586h2.571l5.881,5.881v.88h-8.451Z" transform="translate(-55.704 -19.229)" fill="none" stroke="#515151" stroke-miterlimit="10" stroke-width="1.5" />
              </g>
            </svg>
            <h3 className="text-grey uppercase text-center">
              Expédition sous 48h
            </h3>
          </div>
        </div>
      </div>
      <div style={classes.footer}>
        <div className="w-6/12 md:w-4/12">
          <div style={classes.footerTitle}>
            <a
              href={addProtocol(process.env.GATSBY_SELLER_WEBSITE)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ul>
                <li className="text-xs md:text-sm underline list-none">
                  Recrutement vendeur
                </li>
              </ul>
            </a>
          </div>
        </div>

        <div className="w-6/12 md:w-4/12">
          <ul>
            {menuCategories.map((category, key) => {
              return (

                <li className="underline list-none">
                  <Link key={key} to={`/${lang}${category.link}`} style={classes.menuLink} className="text-xs md:text-sm">
                    {category.title}
                  </Link>
                </li>

              );
            })}
          </ul>
        </div>
        <div className="md:w-4/12" >
          <div
            className={
              "w-full justify-center mt-4 flex  md:mt-0 md:w-4/12 md:justify-evenly "
            }
          >
            <a
              href={"https://www.facebook.com/zawema_officiel-119919646456025/"}
              className="p-4"
              rel="noopener noreferrer"
              aria-label='lien facebook'
            >
              <Icon
                icon={faFacebookF}
                size={size.width <= 640 ? "1x" : "2x"}
                style={{ color: "#515151" }}
                alt=""
              />
            </a>
            <a
              href={"https://www.instagram.com/zawema_officiel/"}
              className="p-4"
              rel="noopener noreferrer"
              aria-label='lien instagram'
            >
              <Icon
                icon={faInstagram}
                size={size.width <= 640 ? "1x" : "2x"}
                style={{ color: "#515151" }}
                alt=""
              />
            </a>
            <a
              href={"https://www.linkedin.com/company/zawema-com/"}
              className="p-4"
              rel="noopener noreferrer"
              aria-label='lien linkedin'
            >
              <Icon
                icon={faLinkedinIn}
                size={size.width <= 640 ? "1x" : "2x"}
                style={{ color: "#515151" }}
                alt=""
              />
            </a>
          </div>
          <div className="pt-4 px-6 md:px-0">
            <h4 className={"font-bold"}>Newsletter</h4>
            <h5 className="pb-2">Inscrivez-vous pour suivre toute l'actualité de Zawema!</h5>
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={subscribe}
            >
              {({ values, errors, touched, handleChange }) => (
                <Form className={`${(subscribeSuccess) ? "hidden" : "w-full flex flex-col md:flex-row xl:w-3/4 max-w-lg space-x-0 md:space-x-2"}`}>
                  <div className="w-full md:w-2/3 lg:w-3/4">
                    <Field
                      id="emailNewsletter"
                      name="emailNewsletter"
                      type="text"
                      aria-label="champs de saisie d'adresse mail pour l'inscription à la newsletter Zawema"
                      value={values.emailNewsletter}
                      className="w-full h-10 pr-8 pl-5 z-0 border-transparent shadow-none"
                      style={classes.newsletterField}
                      onChange={(e) => {
                        handleChange(e);
                        setNewsLetterValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                    />
                    {errors.emailNewsletter && touched.emailNewsletter ? (
                      <div className="text-orange-darkest">{errors.emailNewsletter}</div>
                    ) : null}
                  </div>
                  <div className="w-full mt-2 text-xs md:w-1/3 md:mt-0 md-text-s lg:w-1/4">
                    <button
                      className="bg-orange-dark rounded text-white font-bold hover:bg-orange-chocolate focus:outline-none h-10 w-full"
                      type="submit"
                      disabled={errors.emailNewsletter}
                    >
                      S'inscrire
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {subscribeSuccess ? (
              <div>Vous êtes désormais inscrits à la newsletter</div>
            ) : null}
          </div>
        </div >
      </div >
      <footer style={classes.copy}>
        <p>Zawema &copy; 2022 - Réalisé par BeeForYou</p>
      </footer>
      <CookieConsent />
    </div>
  );
}
