export const menuCategories = [
  {
    title: 'Nous contacter',
    link: "/contact"
  },
  {
    title: 'FAQ',
    link: "/faq"
  },
  {
    title: 'Blog',
    link: "/advice"
  },
  {
    title: 'Mentions légales',
    link: "/legal"
  },
  {
    title: 'CGV',
    link: "/cgv"
  }
];

