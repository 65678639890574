import React from "react";
import { useCart } from "react-use-cart";
import Icon from "../Icon";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'gatsby';

function ShoppingCart({ lang }) {

  const {
    isEmpty,
    totalItems,
  } = useCart();

  if (isEmpty)
    return (
      <div grid grid-flow-col auto-cols-max>
        <Link to={`/${lang}/cart`} className="hidden md:block">
          <Icon icon={faShoppingBasket} size="2x" />
          <span
            className="absolute inline-block w-5 h-5 top-6 -right-3 bg-orange-dark rounded-full text-white text-center text-xs"
          >
            0
          </span>
        </Link>
        <Link to={`/${lang}/cart`} className="block md:hidden">
          <Icon icon={faShoppingBasket} size="1x" />
          <span
            className="absolute inline-block w-4 h-4 top-3 -right-3 bg-orange-dark rounded-full text-white text-center text-xxs"
          >
            0
          </span>
        </Link>
      </div>
    );

  return (
    <div>
      <Link to={`/${lang}/cart`} className="hidden md:block">
        <span className="absolute inline-block w-5 h-5 top-6 -right-3 bg-orange-dark rounded-full text-white text-center text-xs">
          {totalItems}
        </span>
        <div className="hidden md:block">
          <Icon icon={faShoppingBasket} size="2x" />
        </div>
      </Link>
      
      <Link to={`/${lang}/cart`} className="block md:hidden">
        <span className="absolute inline-block w-5 h-5 top-3 -right-3 bg-orange-dark rounded-full text-white text-center text-xxs">
          {totalItems}
        </span>
        <div>
        <Icon icon={faShoppingBasket} size="1x"/>
        </div>
      </Link>
    </div>
  );
}

export default ShoppingCart;