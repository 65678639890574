const getStyles = (theme) => ({

  footer: {
    color: "#838383",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "2%",
    paddingBottom: "2%",
    width: "100%", // keep for article details page layout
    backgroundColor: "#FEF8F5",
    flexWrap:"wrap"

  },
  footerTitle: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width:700px)": {
      paddingTop: "2%",
    },
  },

  contactInfoMail: {
    display: "flex",
  },
  footerContact: {
    color: "#838383",
    textDecoration: "none",
    fontSize: "0.875rem",
  },
  list: {
    listStyle: "none",
    marginTop: "auto",
    paddingLeft: "10%",
    fonSize: "0.875rem",
  },
  icon: {
    color: "#e28743",
  },
  iconGroup: {
    display: "flex",
    justifyContent: "center",
  },
  socialIcon: {
    color: "#e28743",
  },
  listItem: {
    lineHeight: 1.8,
    color: "#838383",
    listStyle: "none",
    paddingRight: "3%",
  },
  menuLink: {
    textDecoration: "none",
  },
  newsletterField: {
    borderRadius: 5
  },
  copy: {
    color: "#838383",
    textAlign: "center",
    paddingTop: "1%",
    paddingBottom: "5%",
    "@media (max-width:500px)": {
      fontSize: "0.7rem",
    },
  },
  legal: {
    color: "#838383",
    float: "bottom",
  },
});

export default getStyles;
