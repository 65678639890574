import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { GlobalStyles } from "twin.macro";
import Header from "../Header";
import Footer from "../Footer";
import CustomAlert from "../Modale";
import { getCurrentSessionLaunched } from "../../state/reducer";

const Layout = ({ children, firstLevelCategories, secondLevelCategories, thirdLevelCategories, lang, currentPath }) => {
  const { popupOpen, popupMessage, popupError, popupId } = useSelector(
    (state) => ({
      popupOpen: state.getIn(["root", "popupOpen"]),
      popupMessage: state.getIn(["root", "popupMessage"]),
      popupError: state.getIn(["root", "popupError"]),
      popupId: state.getIn(["root", "popupId"]),
    })
  );

  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => ({
    isAuthenticated: state.getIn(['root', 'isAuthenticated']),
  }));

  useEffect(() => {
    dispatch(getCurrentSessionLaunched());
  }, [dispatch]);

  return (
    <>
      <GlobalStyles />
      <Header
        currentPath={currentPath}
        firstLevelCategories={firstLevelCategories}
        secondLevelCategories={secondLevelCategories}
        thirdLevelCategories={thirdLevelCategories}
        isAuthenticated={isAuthenticated}
        lang={lang}
      />
      {children}
      <Footer
        lang={lang}
      />
      {/* The layout is used in each page, so the alert popup will appear wherever it's called from */}
      {popupOpen && (
        <CustomAlert
          withError={popupError}
          id={popupId}
          message={popupMessage}
        />
      )}
    </>
  );
};

export default Layout;

Layout.propTypes = {
  firstLevelCategories: PropTypes.array,
  secondLevelCategories: PropTypes.array,
  thirdLevelCategories: PropTypes.array,
};