const getStyles = () => ({
  iconColor: {
    color: "#AFAFAF",
    "&:hover": {
      color: "#515151",
    },
  },
});

export default getStyles;
